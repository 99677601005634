<template>
  <div>
    Home
  </div>
</template>

<script>
import { getHomeRouteForLoggedInUser, getUserData } from '@/auth/utils'

export default {
  data() {
    return {
      userData: getUserData(),
    }
  },
  mounted() {
    this.CheckUserLogin()
  },
  methods: {
    CheckUserLogin() {
      if (this.userData !== null) {
        this.$router.replace(getHomeRouteForLoggedInUser(this.userData.idDoPerfil))
      } else {
        this.$router.replace('/login')
          .then( () => location.reload() )
      }
    },
  },
}
</script>

<style></style>
